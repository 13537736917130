import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Environment
import { environment } from 'environments/environment';

// Rxjs
import { Observable } from 'rxjs';

// Types
import { OrderQuoteAuditRequest } from '../types/order-quote-audit';

@Injectable({
  providedIn: 'root'
})
export class OrderQuoteService {

  constructor(private http: HttpClient) { }

  public getCustomerQuoteAuditByApartmentId(apartmentId: number): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiQwikkitOrdering}api/Quote/GetCustomerQuoteAuditByApartmentId`, {
      params: {
        apartmentId: apartmentId.toString()
      }
    });
  }

  public getIsQuoteAuditHasChangedByApartmentId(apartmentId: number) {
    return this.http.get<boolean>(`${environment.apiQwikkitOrdering}api/Quote/GetIsQuoteAuditHasChangedByApartmentId`, {
      params: {
        apartmentId: apartmentId.toString()
      }
    });
  }

  public deleteCustomerQuoteAudit(body: OrderQuoteAuditRequest): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiQwikkitOrdering}api/Quote/DeleteCustomerQuoteAudit`, body);
  }

  public setNewCustomerQuoteAudit(body: OrderQuoteAuditRequest): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiQwikkitOrdering}api/Quote/SetNewCustomerQuoteAudit`, body);
  }

  public updateExpeditedDatesInQuoteAudit(body: OrderQuoteAuditRequest): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiQwikkitOrdering}api/Quote/UpdateExpeditedDatesInQuoteAudit`, body);
  }

}
